import logo from './logo.svg';
import './App.css';
import { Link } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://pbs.twimg.com/profile_images/1077057579/marcell_400x400.jpg" className="App-logo" alt="logo" />
        
        <p>
          Hallo Arschloch. 
        </p>

        <a href="https://www.youtube.com/watch?v=9bZkp7q19f0&pp=ygUNZ2FuZ25hbSBzdHlsZQ%3D%3D">hier klicken :))</a>

        Impressum: deine Mama
        <Link to="swag">game</Link>
      </header>

    
    </div>
  );
}

export default App;
